<template>
  <div class="book-detail">
    <div class="left" :class="{'is-link': !isDetail}">
      <div class="cover">
        <div class="dbdiv" @click="toDetail">
          <img class="dbimg" :src="getImage" >
        </div>
        <div class="image-links">
        </div>
      </div>
    </div>
    <div class="info" :class="{'is-link': !isDetail}">
      <div class="titleen" @click="toDetail" v-html="book.name" />
      <div class="titlecn" v-html="book.namecn" />
      <div class="detail">
        <div class="detail-row" v-if="book.homeurl">
          <div class="detail-col" style="word-break:break-all;">
            <span class="detail-key">平台网址：</span>
            <span @click="openLink(book.homeurl)" class="detail-value detail-value-url">{{book.homeurl}}</span>
          </div>
        </div>
        <div class="detail-row" v-if="book.manufacturerCustomerName||book.publisher||book.publishYearStart||book.publishYearEnd">
          <div class="detail-col" v-if="book.manufacturerCustomerName">
            <span class="detail-key">数据库厂商：</span>
            <span class="detail-value">{{book.manufacturerCustomerName}}</span>
          </div>
          <div class="detail-col" v-if="book.publisher">
            <span class="detail-key" >出版社：</span>
            <span class="detail-value">{{book.publisher}}</span>
          </div>
          <div class="detail-col" v-if="book.publishYearStart||book.publishYearEnd">
            <span class="detail-key">收录时间范围：</span>
            <span class="detail-value">{{book.publishYearStart}}</span>
            <span class="detail-value" v-if="book.publishYearStart&&book.publishYearEnd">-</span>
            <span class="detail-value">{{book.publishYearEnd}}</span>
          </div>
        </div>
        <div class="detail-row" v-if="book.subject||book.reader">
          <div class="detail-col" v-if="book.subject">
            <span class="detail-key">学科分类：</span>
            <span class="detail-value">{{book.subject}}</span>
          </div>
          <div class="detail-col" v-if="book.reader">
            <span class="detail-key">读者群：</span>
            <span class="detail-value">{{book.reader}}</span>
          </div>
        </div>
        <div class="detail-row" v-if="book.contenttype||book.hierarchy||book.language">
          <div class="detail-col" v-if="book.contenttype">
            <span class="detail-key">内容类型：</span>
            <span class="detail-value">{{book.contenttype}}</span>
          </div>
          <div class="detail-col" v-if="book.hierarchy">
            <span class="detail-key">文献揭示层次：</span>
            <span class="detail-value">{{book.hierarchy}}</span>
          </div>
          <div class="detail-col" v-if="book.language">
            <span class="detail-key">语种：</span>
            <span class="detail-value">{{book.language}}</span>
          </div>
        </div>
        <div class="detail-row" v-if="book.bookCount||book.unitCount||book.salesingle">
          <div class="detail-col" v-if="book.bookCount">
            <span class="detail-key">资源量：</span>
            <span class="detail-value detail-value-tag">{{book.bookCount}}</span>
          </div>
          <div class="detail-col" v-if="book.unitCount">
            <span class="detail-key">订购单元：</span>
            <span class="detail-value detail-value-tag">{{book.unitCount}}</span>
          </div>
          <div class="detail-col" v-if="book.salesingle">
            <span class="detail-key">是否支持单册销售：</span>
            <span class="detail-value">{{book.salesingle}}</span>
          </div>
        </div>
        <div class="units" v-if="false">
          <b>全部订购单元</b>
          <div class="unit-rows">
            <p
              class="row"
              v-for="row in book.relatedlist"
              :key="row.id"
            >
              <i class="dot"/>
              <span class="unit-name">{{ unitName(row.name) }}</span>
              <span><span class="unit-name">{{ row.unitCount || 0 }}</span> 个订购单元</span>
              <span><span class="unit-name">{{ row.bookCount || 0 }}</span> 种资源</span>
            </p>
          </div>
          <div class="check-all">
            <span @click="toDetail">查看全部订购单元>></span>
          </div>
        </div>
      </div>
      <!-- <div class="buttons">
        <el-button @click="toDetail" type="primary">浏览书目</el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import urlSet from '@/requests/url'

export default {
  name: '',
  props: {
    book: Object,
    rows: Array,
    isDetail: {
      type: Boolean,
      default: false
    },
    haveButtons: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      noImg: require('images/cnki.png'),
    }
  },
  computed: {
    getImage() {
      const id = this.book.logopath
      if (!id) return this.noImg
      return id.substring(0, 4) === 'http' ? id : `${urlSet.fileUrl}/api/v1/fileredirect/file?ruid=${id}`
    },
    haveUnits() {
      const units = this.book.relatedlist
      if (!units || !units.length) return false
      return true
    }
  },
  methods: {
    getContent(title, content) {
      if (title != '出版时间范围') return content
      return content + '-' + this.book.publisherYearEnd
    },
    toDetail() {
      if (this.isDetail) return false

      let url = this.$router.resolve({
        name: 'DataDetail',
        query: { id: this.book.ruid }
      })
      window.open(url.href, '_blank')
    },
    unitName(name) {
      if (!name) return ''
      const len = this.getLen(name)
      if (len <= 32) return name
      else return name.slice(0, 32) + '...'
    },
    starData() {
      let body = { stared: this.book.favor, ruid: this.book.ruid }
      this.$emit('star-data', body)
    },
    buttonClick(ruid) {
      console.log('浏览书目：',ruid)
      // let button = btn
      // button.index = this.book.index
      this.$emit('button-click', ruid)
    },
    showInfo(content) {
      if (!content && content != 0) {
        return false
      } else {
        return true
      }
    },
    openLink(content) {
      if(content.startsWith('http')){
        window.open(content)
      }else{
        window.open('http://'+content)
      }
      
    },
    getLen(str) {
      var realLength = 0, len = str.length, charCode = -1;
      for (var i = 0; i < len; i++) {
        charCode = str.charCodeAt(i);
        // 如果是中文则计数+2，否则+1
        if (charCode >= 0 && charCode <= 128) realLength += 1;
        else realLength += 2;
      }
      return realLength;
    }
  }
}
</script>

<style lang="scss" scoped>
  .book-detail {
    display: flex;
    .dbdiv{
      position: relative;
      height: 200px;
      width: 200px;
      border: 1px solid #e1e1e1;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      overflow: hidden;
      .dbimg{
        display: inline-block;
        max-width: 198px;
        max-height: 198px;
        width: auto;
        height: auto;
      }
    }
    .left {
      width: 210px;
      min-width: 210px;
      .cover {
        .image-links {
          margin-top: 15px;
          text-align: center;
          span {
            font-size: 1rem;
            cursor: pointer;
          }
          .share {
            padding-right: 10px;
            border-right: 1px solid #E6E6E6;
          }
          .fav {
            // padding-left: 10px;
            i {
              margin-right: 5px;
            }
          }
        }
      }
      .count {
        margin-top: 15px;
        text-align: center;
        p {
          .name {
            color: #999;
          }
          .value {
            color: #ee4e0f;
          }
        }
      }
    }
    .info {
      word-break: break-all;
      margin-left: 20px;
      .titleen{
        cursor: pointer;
        font-size: 18px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
        max-width:590px;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .titlecn{
        font-size: 18px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        text-align: left;
        color: #333333;
        max-width:590px;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      > * {
        margin-bottom: 10px;
      }
      h4 {
        font-size: 1.2rem;
      }
      > p {
        color: #666;
        font-size: 1.2rem;
      }
      .detail {
        .detail-row{
          display: inline-block;
          .detail-col{
            float: left;
            margin-right: 20px;
            .detail-key{
              font-size: 16px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              text-align: left;
              color: #333333;
            }
            .detail-value{
              font-size: 16px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              text-align: left;
              color: #666666;
            }
            .detail-value-url{
              cursor: pointer;
              color: #2F8BD6;
              text-decoration: underline;
            }
            .detail-value-tag{
              color: #FF5555;
            }
          }
        }
        div {
          line-height: 1.5rem;
          b {
            &::after {
              content: '：';
            }
          }
          .link {
            color: $mainColor;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .inline-row {
          display: inline-block;
          margin-right: 15px;
        }
      }
      .units {
        .unit-rows {
          .row {
            > * {
              margin-right: 20px;
            }
            .dot {
              height: 10px;
              width: 10px;
              background-color: #cecece;
              border-radius: 50%;
              display: inline-block;
            }
            .unit-name {
              color: $mainColor;
              text-decoration: underline;
            }
          }
        }
        .check-all {
          text-align: end;
          padding-right: 80px;
          span {
            cursor: pointer;
            color: $mainColor;
          }
        }
      }
      .buttons {
        margin-top: 20px;
      }
    }
    .is-link {
      h4 {
        cursor: pointer;
        &:hover {
          color: $mainColor;
        }
      }
    }
  }
</style>
