<template>
  <div class="container">
    <div class="content">
      <breadcrumb :items="currentLocation" />
      <div class="filter-box">
        <p>筛选条件：</p>
        <div class="tags">
          <div class="tag" v-for="(tag, idx) in filterTags" :key="tag.id">
            <span>{{ tag.title }}：</span>
            <span class="name">{{ tag.name }}</span>
            <i class="el-icon-close" @click="tagClick(idx)" />
          </div>
        </div>
      </div>
      <subject-classify
        :group="subjectGroup"
        @subject-item="subjectItem"
        v-loading="loading"
      />
      <div class="main" v-loading="loading">
        <left-menu
          :left="leftData"
          :key="leftKey"
          :delTag="delTag"
          @childByValue="getLeftFilter"
        />
        <div class="list-container">
          <div class="list-top">
            <span>
              共
              <span class="total-count">{{ page.total }}</span>
              条记录
            </span>
          </div>
          <div class="sort-search">
            <sort-buttons :sortFilters="orderActions" @change-sort="changeSort" />
            <search-input :key="searchKey" :haveOption="false" @do-search="doSearch" />
          </div>
          <no-content class="no-content" v-if="emptyList" />
          <div class="item-list" v-else>
            <div class="item-box" v-for="(item, index) in bookList" :key="item.ruid">
              <div class="left-box">
                <p>{{ index + 1 + page.pageno * page.pagesize }}</p>
              </div>
              <div class="detail">
                <data-info
                  :book="item"
                  :rows="dataRows"
                  @button-click="buttonClick"
                  @star-data="starData"
                />
              </div>
            </div>
            <list-pager :page="page" @change-page="changePage" />
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showReason"
      title="请输入评论内容"
      width="30%"
    >
      <div class="star-dialog">
        <el-input
          v-model="reason"
          type="textarea"
          maxlength="2000"
          show-word-limit
          :rows="5"
          class="input"
        ></el-input>
        <div class="buttons">
          <el-button type="info" @click="showReason = false">取消</el-button>
          <el-button type="primary" @click="starBook(starItem)">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SubjectClassify from 'components/SubjectClassify'
import Breadcrumb from 'components/Breadcrumb'
import LeftMenu from 'components/LeftMenu'
import SortButtons from 'components/SortButtons'
import SearchInput from 'components/SearchInput'
import DataInfo from 'components/DataInfo'
import ListPager from 'components/ListPager'
import NoContent from 'components/NoContent'

import { listRows } from 'assets/js/dataRows'

export default {
  components: {
    SubjectClassify,
    Breadcrumb,
    LeftMenu,
    SortButtons,
    SearchInput,
    DataInfo,
    ListPager,
    NoContent,
  },
  name: 'DataList',
  data() {
    return {
      currentLocation: [
        {
          name: '资源首页',
          path: '/home',
        },
        {
          name: '数据库',
          path: '',
        },
      ],
      subjectGroup: null,
      page: {
        pageno: 0,
        pagesize: 10,
        total: 0,
      },
      order: {
        orderfield: '',
        ordertype: 'D',
      },
      loading: false,
      bookList: [],
      leftData: [],
      classify: [],
      leftFilter: [],
      filter: [],
      orderActions: [],
      searchOptions: [],
      delTag: null,
      dataRows: listRows,
      checkAll: false,
      isIndeterminate: false,
      selected: [],
      subject: '',
      leftKey: 0,
      searchKey: 0,
      fromLeft: false,
      searching: false,
      showDialog: false,
      filterTags: [],
      addItems: [],
      topFilter: [],
      showReason: false,
      reason: '',
      starItem: null,
    }
  },
  computed: {
    emptyList() {
      const list = this.bookList
      return list.length < 1 ? true : false
    },
  },
  methods: {
    starData(data) {
      if (data.stared) return this.cancelStar(data.ruid)
      else return this.doStar(data.ruid)
    },
    async cancelStar(id) {
      const res = await this.$http.get(
        '/api/v1/my/favor/cancel?dataType=1987055001&outRuid=' + id
      )
      if (res.cxajaxrc != 0 || !res.returnvalue) return false

      this.$message.success('取消收藏成功')
      this.getListData()
    },
    async doStar(id) {
      const res = await this.$http.get(
        '/api/v1/my/favor/do?dataType=1987055001&outRuid=' + id
      )
      if (res.cxajaxrc != 0 || !res.returnvalue) return false

      this.$message.success('收藏成功')
      this.getListData()
    },
    buttonClick(button) {
      let item = this.bookList[button.index]
      switch (button.text) {
        case '收藏到书单':
          this.addItems = [{ id: item.id, ruid: item.ruid }]
          this.showDialog = true
          break
        case '评论':
          this.starItem = item
          this.showReason = true
          break
      }
    },
    async starBook(item) {
      if (!item) return this.$message.error('请输入评论内容')
      this.loading = true
      // let userId = localStorage.getItem('userRuid')
      let userId = '1830d8a6020f001'
      // const res = await this.$http.get(`api/v1/user/personaldata/addStar?userRuid=${userId}&dataId=${item.id}`)
      const body = {
        userId,
        dataId: [item.id], //资料id，多个时逗号隔开
        type: 'EDITOR',
        reason: this.reason,
      }
      const res = await this.$http.post('/api/v1/user/personaldata/addStar', body)
      if (res.cxajaxrc != 0) {
        this.loading = false
        return false
      }

      this.loading = false
      this.$message.success('评论成功')
      this.showReason = false
      this.getListData()
    },
    tagClick(index) {
      let tags = this.filterTags
      let tag = tags[index]
      if (tag.advanced) {
        tags.splice(index, 1)
        this.filterTags = tags
        let filters = this.topFilter.filter((item) => {
          return item.title != tag.title
        })
        this.topFilter = filters
        this.getListData()
      } else {
        this.delTag = tags[index]
        tags.splice(index, 1)
      }
    },
    changeSort(obj) {
      this.order = obj
      this.getListData()
    },
    doSearch(obj) {
      this.page.pageno = 0
      let filter = {
        logicalop: 'and',
        relationop: 'like',
        fieldname: '10110001,10100001,10120001',
        word: obj.value,
      }
      this.searching = true
      this.filter = filter
      this.getListData()
    },
    resetPage() {
      this.subject = ''
      this.classify = []
      this.leftFilter = []
      this.filter = []
      this.filterTags = []
      this.delTag = null
      this.page.pageno = 0
      this.selected = []
      this.checkAll = false
      this.isIndeterminate = false
    },
    subjectItem(item) {
      this.searchKey++
      this.resetPage()
      if (!item.urlappend) {
        this.resetPage()
        this.classify = []
      } else {
        this.subject = item.urlappend
        const filter = item.filterfield[0]
        this.classify = [filter]
      }
      this.filterTags = this.filterTags.filter((tag) => tag.advanced)
      this.leftFilter = []
      this.leftKey++
      this.getListData()
    },
    selectAll(val) {
      this.checkAll = val
      const list = this.bookList
      if (val) this.selected = list.map((item) => item.id)
      else this.selected = []
      this.isIndeterminate = false
    },
    changeSelect() {
      const selected = this.selected.length
      const list = this.bookList.length
      if (selected === list) {
        this.isIndeterminate = false
        this.checkAll = true
      } else {
        this.checkAll = false
        if (!selected) this.isIndeterminate = false
        else this.isIndeterminate = true
      }
    },
    getLeftFilter(filter) {
      this.page.pageno = 0
      this.selected = []
      this.checkAll = false
      this.isIndeterminate = false

      let array = []
      let existTags = []
      let newTags = []
      filter.forEach((item) => {
        if (item.filterflag == 1) {
          let postbody = {
            logicalop: 'and',
            fieldname: item.filterfield,
            relationop: 'raw',
            word: item.ruid,
          }
          array.push(postbody)
        } else if (item.filterflag == 100) {
          item.filterfield.forEach((item) => {
            array.push(item)
          })
        } else {
          let filterTags = this.filterTags
          let target = filterTags.find((tag) => tag.name === item.name)
          if (!target) {
            newTags.push(item)
          } else {
            existTags.push(item)
          }
        }
      })
      let publishTags = this.filterTags.filter((tag) => tag.advanced)
      let finalTags = newTags.concat(existTags)
      this.filterTags = publishTags.concat(finalTags)
      // this.filterTags = filterTags

      this.filter = []
      this.searchKey++

      this.leftFilter = array
      this.fromLeft = true
      this.getListData()
    },
    changePage(page) {
      this.selected = []
      this.checkAll = false
      this.isIndeterminate = false
      this.page.pageno = page

      this.getListData()
    },
    getFilter() {
      // 合并过滤条件
      let topFilter = this.topFilter
      let classFilter = topFilter.concat(this.classify)
      let leftFilter = classFilter.concat(this.leftFilter)
      let searchFilter = leftFilter.concat(this.filter)
      // let filter = this.classify.concat(this.leftFilter)
      // filter = filter.concat(this.filter)
      // filter = filter.concat(this.topFilter)
      let bodyData = {
        classify: classFilter,
        leftFilter: leftFilter,
        filter: searchFilter,
      }

      if (!this.topFilter.length) {
        // 判断是何处来的条件
        if (this.fromLeft) {
          delete bodyData.classify
        } else if (this.searching) {
          delete bodyData.classify
          delete bodyData.leftFilter
        }
        this.fromLeft = false
        this.searching = false
      }

      return bodyData
    },
    async getListData() {
      this.loading = true

      // 拼接参数
      let obj = Object.assign(this.page, this.order)
      let param = this.getParams(obj)
      param += this.subject
      const bodyData = this.getFilter()

      // 发送请求
      const res = await this.$http.post(
        '/api/v1/datasearch/ebook_collection_list' + param,
        bodyData
      )
      if (res.cxajaxrc != 0) {
        this.loading = false
        return false
      }

      const val = res.returnvalue
      if (val) {
        this.page.total = val.recordcount

        if (!val.recordlist) this.bookList = []
        else
          this.bookList = val.recordlist.map((item, index) => {
            item.index = index
            const buttons = item.recordaction
            if (buttons) {
              item.recordaction = buttons.map((btn) => {
                btn.type = btn.text.includes('评论') ? 'primary' : 'plain'
                return btn
              })
            }
            return item
          })

        // this.subjectGroup = val.classifygroup
        if (this.subjectGroup == null) {
          this.subjectGroup = val.classifygroup
        } else {
          let cache = JSON.parse(JSON.stringify(this.subjectGroup))
          cache.forEach((item1) => {
            item1.items = []
            val.classifygroup.forEach((item2) => {
              if (item1.root == item2.root) {
                item1.items = item2.items
              }
            })
          })
          this.subjectGroup = cache
        }
        this.orderActions = val.orderactions
        this.leftData = val.left.map((item) => {
          item.newArrays = []
          return item
        })
      }

      // this.checkSearch()
      this.loading = false
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    getParams(obj) {
      let param = ''
      let idx = 0
      for (let key in obj) {
        if ((!obj[key] && obj[key] != 0) || key == 'total') continue
        let dot = idx === 0 ? '?' : '&'
        param += dot + (key + '=' + obj[key])
        idx += 1
      }
      return param
    },
    checkSearch() {
      const filter = sessionStorage.getItem('searchFilter')
      if (!filter) return false
      const filterObj = JSON.parse(filter) || {}
      this.topFilter = filterObj.search
    },
    getSearchFilter() {
      let filterStr = sessionStorage.getItem('searchFilter')
      if (filterStr) {
        let searchFilter = JSON.parse(filterStr)
        let filter = searchFilter.top[0]
        this.topFilter.push(filter)
        this.filterTags.push({
          title: '自定义条件',
          name: filter.word
            .split("<font style='color:red'>")
            .join('')
            .split('</font>')
            .join(''),
          fieldname: filter.fieldname,
          advanced: true,
        })
      }
      this.getListData()
    },
  },
  created() {},
  mounted() {
    // this.getListData()
    this.getSearchFilter()
    this.$bus.$on('refreshDataList', () => {
      this.refKey++
      this.leftKey++
      this.searchKey++
      this.resetPage()
      this.filterTags = []
      this.topFilter = []
      let filter = sessionStorage.getItem('searchFilter')
      let searchFilter = JSON.parse(filter)
      const obj = searchFilter.top[0]
      if (obj.word) {
        this.filterTags.push({
          title: '自定义条件',
          name: obj.word
            .split("<font style='color:red'>")
            .join('')
            .split('</font>')
            .join(''),
          fieldname: obj.fieldname,
          advanced: true,
        })
      }
      this.topFilter = searchFilter.top
      sessionStorage.removeItem('searchFilter')
      this.getListData()
    })
  },
  beforeDestroy() {
    this.$bus.$off('refreshDataList')
  },
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #eeefef;
  .content {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    > * {
      margin-bottom: 10px;
    }
    .filter-box {
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 20px;
      p {
        min-width: 100px;
        text-align: center;
      }
      .tags {
        .tag {
          padding: 5px;
          margin: 5px;
          border: 1px solid #ddd;
          display: inline-block;
          .name {
            color: $mainColor;
          }
          i {
            cursor: pointer;
            margin-left: 3px;
          }
        }
      }
    }
    .main {
      display: flex;
      /deep/ .container_left {
        width: 300px;
        min-width: 300px;
        max-width: 300px;
      }
      .list-container {
        padding: 20px;
        width: 100%;
        margin-left: 10px;
        background: #fff;
        min-width: 0;
        > * {
          margin-bottom: 20px;
        }
        .list-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #eee;
          padding: 15px;
          .total-count {
            color: #f00;
            margin: 0 5px;
          }
          .el-button {
            margin-left: 20px;
          }
        }
        .sort-search {
          display: flex;
          justify-content: space-between;
        }
        .no-content {
          height: 500px;
        }
        .item-list {
          // border-top: 1px solid $mainColor;
          padding-top: 10px;
          .item-box {
            display: flex;
            padding: 20px 0;
            border-bottom: 1px solid #e6e6e6;
            .left-box {
              width: 60px;
              text-align: center;
              p {
                font-size: 1.2rem;
                margin-bottom: 5px;
              }
            }
            .detail {
              flex: 1;
            }
          }
        }
      }
    }
  }
}
.star-dialog {
  /deep/ .el-input__count {
    margin-right: 20px;
  }
  .buttons {
    display: block;
    margin-top: 20px;
    text-align: right;
  }
}
</style>
